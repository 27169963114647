<h2>{{ label }}</h2>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div class="mat-elevation-z8 aims-table-container">
  <mat-toolbar
    class="toolbar-container"
    *ngIf="
      permissions != null &&
      (permissions.create || toolbarDef || permissions.export || permissions.print || permissions.clear !== false)
    "
  >
    <mat-toolbar-row class="toolbar-position">
      <aims-common-button
        id="add-button"
        *ngIf="permissions !== null && permissions.create && !isEditing"
        aria-label="Add"
        (click)="handleAddRow()"
      >
        <mat-icon>add</mat-icon>Add
      </aims-common-button>
      <aims-common-export-to-excel
        id="export-button"
        *ngIf="data && permissions.export"
        [tableData]="data.filteredData"
        [selected]="selection.select?.selected"
        [header]="header"
        [exportFunction]="exportFunction"
      ></aims-common-export-to-excel>
      <aims-common-button
        id="print-button"
        *ngIf="permissions !== null && permissions.print"
        aria-label="Print"
        (click)="handlePrint()"
      >
        <mat-icon>print</mat-icon>Print {{ selection.select?.selected.length > 0 ? 'Selected' : '' }}
      </aims-common-button>
      <ng-template
        [ngTemplateOutlet]="toolbarDef"
        [ngTemplateOutletContext]="{ $implicit: selection.select?.selected }"
      >
      </ng-template>
      <!-- Right pushed -->
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Table menu" class="push">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="handleClear()" *ngIf="permissions !== null && permissions.clear !== false">
          <mat-icon>clear</mat-icon>
          <span>Clear Filters & Sort</span>
        </button>
        <button mat-menu-item (click)="everyChange()" *ngIf="hasGridAlert" [ngClass]="(everyChangeAlertSelected == true)? 'alert-on' : 'none'" >
          <mat-icon>change_history</mat-icon>
          <span>Notify Me Every Change</span>
        </button>
        <button mat-menu-item (click)="dailyChange()" *ngIf="hasGridAlert"  [ngClass]="(dailyChangeAlertSelected == true)? 'alert-on' : 'none'" >
          <mat-icon>track_changes</mat-icon>
          <span>Notify Me Every 24 Hours</span>
        </button>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>
  <table mat-table [dataSource]="data" matSort (matSortChange)="handleSortChange($event)" multiTemplateDataRows>
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail" [@detailExpand]="shallowObjectOrKeyEqual(element, expandedElement) ? 'expanded' : 'collapsed'">
          <ng-template
            *ngIf="shallowObjectOrKeyEqual(element, expandedElement)"
            [ngTemplateOutlet]="expandedRowDef"
            [ngTemplateOutletContext]="{ $implicit: element }"
          >
          </ng-template>
        </div>
      </td>
    </ng-container>
<!-- Render filter in header -->
    <ng-container *ngFor="let col of header; index as i">
      <ng-container [matColumnDef]="col.key + '-filter'">
        <th
          mat-header-cell
          *matHeaderCellDef
          [id]="'filter-' + col.key"
          [ngSwitch]="col.filterType"
        >
          <ng-container *ngIf="col.filter !== false">
            <aims-common-table-date-filter
              *ngSwitchCase="'date'"
              [column]="col"
              (change)="filterChange($event, col)"
              [value]="filterValues[col.key]"
            ></aims-common-table-date-filter>

            <mat-form-field *ngSwitchCase="'boolean'" appearance="fill" class="form-filter">
              <mat-label>Filter</mat-label>
              <mat-select [(ngModel)]="filterValues[col.key]" (selectionChange)="filterChange($event, col)">
                <mat-option value=""></mat-option>
                <mat-option value="true">Is True</mat-option>
                <mat-option value="false">Is False</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngSwitchCase="'dynamic'">
              <ng-template
                [ngTemplateOutlet]="dynamicRowDefs[col.key].filtersTpl.tpl"
                [ngTemplateOutletContext]="getFilterTemplateContext(col)"
              >
              </ng-template>
            </div>
            <mat-form-field *ngSwitchDefault appearance="fill" class="form-filter">
              <input
                class="filter-field"
                matInput
                type="text"
                placeholder="Filter"
                autocomplete="off"
                (change)="filterChange($event, col)"
                [(ngModel)]="filterValues[col.key]"
              />
              <button
                mat-button
                type="button"
                *ngIf="filterValues[col.key]"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="filterChange($event, col)"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </ng-container>
        </th>
      </ng-container>

      <ng-container [ngSwitch]="col.type">
        <!-- Main column definitions -->
        <ng-container *ngSwitchCase="'checkbox'" matColumnDef="{{ col.key }}">
          <th mat-header-cell
            *matHeaderCellDef
            [id]="'checkbox-' + col.key"
            [style.min-width.px]="col.width"
          >
            <div class="hover-text">
            <mat-checkbox
              (change)="$event ? masterToggle(col.key) : null"
              [checked]="selection[col.key].hasValue() && isAllSelected(col.key)"
              [indeterminate]="selection[col.key].hasValue() && !isAllSelected(col.key)"
              [aria-label]="checkboxLabel(null, col)"
              class="checkbox-header"
            >
            </mat-checkbox>
              <span class="tooltip-text" id="top">{{col.tooltip}}</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; dataIndex as idx">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="handleSelection($event, col.key, element)"
              [checked]="selection[col.key].isSelected(element)"
              [aria-label]="checkboxLabel(element, col)"
              [color]="primary"
            >
            </mat-checkbox>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <ng-template
              *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
              [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
            ></ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="'rowExpand'" matColumnDef="{{ col.key }}">
          <th mat-header-cell
            *matHeaderCellDef
            [id]="'rowExpand-' + col.key"
            [style.min-width.px]="col.width || 30">
            {{ col.label }}
          </th>
          <td mat-cell *matCellDef="let element; let row" class="buttonColumn">
            <aims-common-button-mini
              [attr.aria-label]="!shallowObjectOrKeyEqual(element, expandedElement)? 'Expand the row' : 'Collapse the row'"
              (click)="handleExpandRowClick($event, row, element, col)"
              *ngIf="edittingRowElement !== element && !element._add"
            >
              <mat-icon *ngIf="shallowObjectOrKeyEqual(element, expandedElement)" color="primary">remove</mat-icon>
              <mat-icon
                *ngIf="!shallowObjectOrKeyEqual(element, expandedElement)"

                color="primary">add</mat-icon>
            </aims-common-button-mini>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <ng-template
              *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
              [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
            ></ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="'dynamic'" matColumnDef="{{ col.key }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-background table-color"
            [id]="'dynamic-' + col.key"
            [ngClass]="col.headerClass"
            [style.width.px]="col.width"
          >
            <span *ngIf="!dynamicRowDefs[col.key].headerTpl">
              <div class="hover-text">
                {{ col.label }}
                <span *ngIf="col.tooltip"><i class="fas fa-question-circle"></i></span>
                <span class="tooltip-text" id="top">{{col.tooltip}}</span>
              </div>  
            </span>
            <span *ngIf="dynamicRowDefs[col.key].headerTpl">
              <ng-template
                [ngTemplateOutlet]="dynamicRowDefs[col.key].headerTpl.tpl"
                [ngTemplateOutletContext]="{ $implicit: element }"
              ></ng-template>
            </span>
          </th>
          <td mat-cell *matCellDef="let element; dataIndex as idx">
            <!--
              The dynamic template allows let-formGroup="formGroup" and let-controls="controls" (array of formGroups) to be used with very little overhead
                - Warning: When they are used, it increases the device ram usage. Only grab when needed
                - This allows complex calculations in each column
                - But avoid calling a function in your component.html, use {{ interpolation }}. Avoid function calls, it's called EVERY cycle!
            -->
            <div [style.width.px]="col.width">
            <ng-template
              [ngTemplateOutlet]="
                edittingRowElement == element && col.editable !== false && dynamicRowDefs[col.key].editModeTpl
                  ? dynamicRowDefs[col.key].editModeTpl.tpl
                  : dynamicRowDefs[col.key].viewModeTpl.tpl
              "
              [ngTemplateOutletContext]="{
                $implicit: element,
                edittingRowElement: edittingRowElement,
                mode: edittingRowElement == element && col.editable !== false ? 'edit' : 'view',
                idx: getIdx(idx, element),
                dynamicControl: getControl(getIdx(idx, element), col.key),
                formGroup: getControlForm(getIdx(idx, element)),
                controls: controls,
                width: col.width
              }"
            >
            </ng-template>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <ng-template
              *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
              [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
            ></ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="'button'" matColumnDef="{{ col.key }}">
          <th mat-header-cell
            *matHeaderCellDef
            class="header-background table-color table-button-header"></th>
          <td mat-cell *matCellDef="let element; let isNew = isNew" class="buttonColumn">
            <a
              mat-mini-fab
              color="basic"
              [aria-label]="getButtonLabel(col, element)"
              [title]="getButtonLabel(col, element)"
              (click)="col.events?.click(element)"
              *ngIf="col.events"
            >
              <mat-icon [color]="getButtonColor(col, element)">
                {{ getButtonIcon(col, element) }}
              </mat-icon>
            </a>

            <a
              mat-mini-fab
              color="basic"
              [aria-label]="col.label"
              [title]="col.label"
              *ngIf="col.linkId && col.prefix"
              [routerLink]="col.prefix + element[col.linkId]"
            >
              <mat-icon color="primary">launch</mat-icon>
            </a>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <ng-template
              *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
              [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
            ></ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="'detailsButton'" matColumnDef="{{ col.key }}">
          <ng-container *ngIf="permissions.read && col.prefix && col.linkId">
            <th mat-header-cell
              *matHeaderCellDef
              class="header-background table-color table-button-header"></th>
            <td mat-cell *matCellDef="let element; let isNew = isNew" class="buttonColumn">
              <ng-container >
                <a
                mat-mini-fab
                color="basic"
                [aria-label]="getButtonLabel(col, element)"
                [title]="getButtonLabel(col, element)"
                (click)="col.events?.click(element)"
                *ngIf="col.events"
                >
                  <mat-icon [color]="getButtonColor(col, element)">
                    {{ getButtonIcon(col, element) }}
                  </mat-icon>
                </a>

                <a
                  mat-mini-fab
                  color="basic"
                  [aria-label]="col.label"
                  [title]="col.label"
                  *ngIf="col.linkId && col.prefix"
                  [routerLink]="col.prefix + element[col.linkId]"
                >
                  <mat-icon color="primary">launch</mat-icon>
                </a>
              </ng-container>
            </td>
            <td mat-footer-cell *matFooterCellDef>
              <ng-template
                *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
                [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
              ></ng-template>
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'editButton'" matColumnDef="{{ col.key }}">
          <th mat-header-cell *matHeaderCellDef class="header-background table-color table-button-header"></th>
          <td mat-cell class="mat-group-cell" *matCellDef="let element; let row; dataIndex as idx" class="buttonColumn">
            <aims-common-button-mini
              aria-label="Edit"
              title="Edit"
              (click)="handleEditRow($event, row, 'edit', idx, element)"
              *ngIf="getUpdatePermission(element) && edittingRowElement !== element"
            >
              <mat-icon color="primary">create</mat-icon>
            </aims-common-button-mini>

            <aims-common-button-mini
              title="Delete"
              aria-label="Delete"
              (click)="handleDeleteRow($event, row, 'delete', idx)"
              *ngIf="edittingRowElement === element && permissions.delete && !element._add"
            >
              <mat-icon color="warn">delete</mat-icon>
            </aims-common-button-mini>

            <aims-common-button-mini
              title="Close"
              aria-label="Close"
              (click)="handleEditRow($event, row, 'cancel', idx)"
              *ngIf="edittingRowElement === element"
            >
              <mat-icon color="basic">close</mat-icon>
            </aims-common-button-mini>

            <aims-common-button-mini
              title="Save Changes"
              aria-label="Save Changes"
              (click)="handleEditRow($event, row, 'save', idx)"
              [disabled]="getControlForm(getIdx(idx, element)).invalid"
              *ngIf="edittingRowElement === element && !element._add"
            >
              <mat-icon color="primary">save</mat-icon>
            </aims-common-button-mini>

            <aims-common-button-mini
              title="Add Changes"
              aria-label="Add Changes"
              (click)="handleEditRow($event, row, 'add', idx)"
              [disabled]="getControlForm(getIdx(idx, element)).invalid"
              *ngIf="edittingRowElement === element && element._add"
            >
              <mat-icon color="primary">save</mat-icon>
            </aims-common-button-mini>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <ng-template
              *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
              [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
            ></ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="'boolean'" matColumnDef="{{ col.key }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-background table-color"
            [id]="'boolean-' + col.key"
            [ngClass]="col.headerClass"
          >
            <div class="hover-text">
              {{ col.label }}
              <span *ngIf="col.tooltip"><i class="fas fa-question-circle"></i></span>
              <span class="tooltip-text" id="top">{{col.tooltip}}</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; dataIndex as idx">
            <aims-common-table-editable
              [inputMode]="edittingRowElement == element && col.editable !== false ? 'edit' : 'view'"
            >
              <ng-template viewMode>
                <div class="text-center">
                  <mat-checkbox [checked]="getValue(element, col.key)" disabled="true"></mat-checkbox>
                </div>
              </ng-template>
              <ng-template editMode>
                <div class="text-center">
                  <mat-checkbox [formControl]="getControl(getIdx(idx, element), col.key)"></mat-checkbox>
                </div>
              </ng-template>
            </aims-common-table-editable>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <ng-template
              *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
              [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
            ></ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="'date'" matColumnDef="{{ col.key }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-background table-color"
            [id]="'date-' + col.key"
            [ngClass]="col.headerClass"
          >
            <div class="hover-text">
              {{ col.label }}
              <span *ngIf="col.tooltip"><i class="fas fa-question-circle"></i></span>
              <span class="tooltip-text" id="top">{{col.tooltip}}</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; dataIndex as idx">
            <aims-common-table-editable
              [inputMode]="edittingRowElement == element && col.editable !== false ? 'edit' : 'view'"
            >
              <ng-template viewMode>{{ getValue(element, col.key) | date: col.format }}</ng-template>
              <ng-template editMode>
                <mat-form-field appearance="fill" class="editable-text-field">
                  <mat-label>Choose a date</mat-label>
                  <input [style.width.px]="col.width" matInput [formControl]="getControl(getIdx(idx, element), col.key)" [matDatepicker]="picker" autocomplete="off" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="getControl(getIdx(idx, element), col.key)?.hasError('required')">
                    Field is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </ng-template>
            </aims-common-table-editable>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <ng-template
              *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
              [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
            ></ng-template>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="'number'" matColumnDef="{{ col.key }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-background table-color"
            [id]="'number-' + col.key"
            [ngClass]="col.headerClass"
          >
            <div class="hover-text">
              {{ col.label }}
              <span *ngIf="col.tooltip"><i class="fas fa-question-circle"></i></span>
              <span class="tooltip-text" id="top">{{col.tooltip}}</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; dataIndex as idx">
            <aims-common-table-editable
              [inputMode]="edittingRowElement == element && col.editable !== false ? 'edit' : 'view'"
            >
              <ng-template viewMode>{{ getValue(element, col.key) }}</ng-template>
              <ng-template editMode>
                <mat-form-field appearance="fill" class="editable-text-field">
                  <input [style.width.px]="col.width" matInput type="number" [formControl]="getControl(getIdx(idx, element), col.key)" autocomplete="off" />
                  <mat-error *ngIf="getControl(getIdx(idx, element), col.key).hasError('required')">
                    Field is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="getControl(getIdx(idx, element), col.key).hasError('min')">
                    Field must meet <strong>minimum</strong> value
                  </mat-error>
                </mat-form-field>
              </ng-template>
            </aims-common-table-editable>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <ng-template
              *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
              [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
            ></ng-template>
          </td>
        </ng-container>


        <ng-container *ngSwitchCase="'html'" matColumnDef="{{ col.key }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-background table-color"
            [id]="'default-' + col.key"
            [ngClass]="col.headerClass"
          >
            <div class="hover-text">
              {{ col.label }}
              <span *ngIf="col.tooltip"><i class="fas fa-question-circle"></i></span>
              <span class="tooltip-text" id="top">{{col.tooltip}}</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; dataIndex as idx">
            <aims-common-table-editable
              [inputMode]="edittingRowElement == element && col.editable !== false ? 'edit' : 'view'"
            >
              <ng-template viewMode>
                <span [innerHtml]="getValue(element, col.key)" ></span>
              </ng-template>

              <ng-template editMode>
                <mat-form-field appearance="fill" class="editable-text-field">
                  <input [style.width.px]="col.width" matInput type="text" [formControl]="getControl(getIdx(idx, element), col.key)" autocomplete="off" />
                  <mat-error *ngIf="getControl(getIdx(idx, element), col.key).hasError('required')">
                    Field is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="getControl(getIdx(idx, element), col.key).hasError('maxlength')">
                    Field has <strong>too many characters</strong>
                  </mat-error>
                </mat-form-field>
              </ng-template>
            </aims-common-table-editable>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <ng-template
              *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
              [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
            ></ng-template>
          </td>
        </ng-container>


        <ng-container *ngSwitchDefault matColumnDef="{{ col.key }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="header-background table-color"
            [id]="'default-' + col.key"
            [ngClass]="col.headerClass"
            [style.min-width.px]="col.width"
          >
            <div class="hover-text">
              {{ col.label }}
              <span *ngIf="col.tooltip"><i class="fas fa-question-circle"></i></span>
              <span class="tooltip-text" id="top">{{col.tooltip}}</span>
            </div>
          </th>

          <td mat-cell *matCellDef="let element; dataIndex as idx">
            <aims-common-table-editable
              [inputMode]="edittingRowElement == element && col.editable !== false ? 'edit' : 'view'"
            >
              <ng-template viewMode>{{ getValue(element, col.key) }}</ng-template>
              <ng-template editMode>
                <mat-form-field appearance="fill" class="editable-text-field">
                  <input [style.width.px]="col.width" matInput type="text" [formControl]="getControl(getIdx(idx, element), col.key)" autocomplete="off" />
                  <mat-error *ngIf="getControl(getIdx(idx, element), col.key)?.hasError('required')">
                    Field is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="getControl(getIdx(idx, element), col.key)?.hasError('maxlength')">
                    Field has <strong>too many characters</strong>
                  </mat-error>
                </mat-form-field>
              </ng-template>
            </aims-common-table-editable>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <ng-template
              *ngIf="dynamicRowDefs[col.key]?.footerTpl !== undefined"
              [ngTemplateOutlet]="dynamicRowDefs[col.key]?.footerTpl?.tpl"
            ></ng-template>
          </td>
        </ng-container>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: stickyHeader"></tr>

    <ng-container *ngIf="permissions.filter !== false">
      <tr mat-header-row *matHeaderRowDef="displayedFilterColumns" class="table-second-header-row"></tr>
    </ng-container>

    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; dataIndex as idx"
      [class.expanded-row]="shallowObjectOrKeyEqual(row, expandedElement)"
      (click)="handleRowClick($event, row, 'edit', idx)"
      [ngClass]="getRowClass(row, getIdx(idx, row))"
    ></tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    <tr *matNoDataRow>
      <td colspan="2">
        <h3 class="no-records">
          <mat-icon aria-hidden="false" aria-label="Empty records">message</mat-icon><span>No records found</span>
        </h3>
      </td>
    </tr>

    <ng-container *ngIf="footer">
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </ng-container>
  </table>

  <mat-toolbar class="toolbar-container">
    <mat-toolbar-row class="toolbar-position">
      <span class="footer-spacer"></span>
      <mat-paginator
        class="paginator"
        [hidePageSize]="hidePageSize"
        (page)="handlePaginatorChanges($event)"
        [pageSizeOptions]="[5, 10, 20, 40, 50, 100, 1000]"
        showFirstLastButtons
      ></mat-paginator>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
